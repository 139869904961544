import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { TwitterService } from '../services/twitter.service';
import { LegendControl } from './LegendControl';

interface TweetWithMetrics {
  tweet_id: string;
  text: string;
  created_at: string;
  author_id: string;
  public_metrics: {
    retweet_count: number;
    reply_count: number;
    like_count: number;
    quote_count: number;
    bookmark_count: number;
    impression_count: number;
  };
}

interface EngagementVisualizationProps {
  selectedUsers: string[];
  onTweetsUpdate?: (tweets: { [key: string]: TweetWithMetrics[] }) => void;
  userHandles: { [key: string]: string };
  onDaySelect?: (tweetIds: string[]) => void; 
  monthWindow: string; 
  yMax?: number; 
  userToGroup: { [key: string]: string };
}

export const EngagementVisualization: React.FC<EngagementVisualizationProps> = ({
  selectedUsers,
  onTweetsUpdate,
  onDaySelect,
  monthWindow,
  yMax = 15,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [tweets, setTweets] = useState<{ [key: string]: TweetWithMetrics[] }>({});

  useEffect(() => {
    const fetchTweets = async () => {
      const newTweets: { [key: string]: TweetWithMetrics[] } = {};
      try {
        const response = await TwitterService.getUserTweetsMultiple(selectedUsers) as unknown;
        const data = (response as { data: Array<{
          id: string;
          text: string;
          created_at: string;
          author_id: string;
          public_metrics: TweetWithMetrics['public_metrics'];
        }> }).data;

        data.forEach((tweet) => {
          const userId = tweet.author_id;
          if (!newTweets[userId]) newTweets[userId] = [];
          newTweets[userId].push({
            tweet_id: tweet.id,
            text: tweet.text,
            created_at: tweet.created_at,
            author_id: tweet.author_id,
            public_metrics: tweet.public_metrics
          });
        });
        setTweets(newTweets);
        onTweetsUpdate?.(newTweets);
      } catch (error) {
        console.error('Error fetching engagement tweets:', error);
      }
    };

    if (selectedUsers.length > 0) {
      fetchTweets();
    } else {
      setTweets({});
    }
  }, [selectedUsers, onTweetsUpdate]);

  useEffect(() => {
    if (!svgRef.current || !Object.keys(tweets).length) return;

    const allTweets = Object.values(tweets).flat();
    if (allTweets.length === 0) return;

    const startDate = new Date(monthWindow); // e.g., "2025-03-01"
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 1); // Exact next month

    const engagementByDate: { [key: string]: { likes: number[]; retweets: number[]; replies: number[]; tweets: TweetWithMetrics[] } } = {};
    allTweets
      .filter(tweet => {
        const tweetDate = new Date(tweet.created_at);
        return tweetDate >= startDate && tweetDate < endDate;
      })
      .forEach(tweet => {
        const date = new Date(tweet.created_at).toISOString().split('T')[0];
        if (!engagementByDate[date]) engagementByDate[date] = { likes: [], retweets: [], replies: [], tweets: [] };
        engagementByDate[date].likes.push(tweet.public_metrics.like_count);
        engagementByDate[date].retweets.push(tweet.public_metrics.retweet_count);
        engagementByDate[date].replies.push(tweet.public_metrics.reply_count);
        engagementByDate[date].tweets.push(tweet);
      });

    const dateRange = d3.timeDay.range(startDate, endDate).map(date => {
      const dateStr = date.toISOString().split('T')[0];
      const data = engagementByDate[dateStr] || { likes: [0], retweets: [0], replies: [0], tweets: [] };
      return {
        date: dateStr,
        medianLikes: d3.median(data.likes) || 0,
        medianRetweets: d3.median(data.retweets) || 0,
        medianReplies: d3.median(data.replies) || 0,
        tweets: data.tweets,
      };
    });

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const margin = { top: 10, right: 20, bottom: 30, left: 60 };
    const width = svgRef.current.clientWidth - margin.left - margin.right;
    const height = 100;

    svg.attr('width', width + margin.left + margin.right)
       .attr('height', height + margin.top + margin.bottom);

    const g = svg.append('g')
       .attr('transform', `translate(${margin.left},${margin.top})`);

    const xScale = d3.scaleBand()
      .domain(dateRange.map(d => d.date))
      .range([0, width])
      .padding(0.1);

    const yScale = d3.scaleLinear()
      .domain([0, Math.max(yMax, d3.max(dateRange, d => Math.max(d.medianLikes, d.medianRetweets, d.medianReplies)) || 15)])
      .range([height, 0])
      .nice();

    const animateLine = (path: d3.Selection<SVGPathElement, any, any, any>) => {
      const totalLength = path.node()!.getTotalLength();
      path
        .attr('stroke-dasharray', totalLength + ' ' + totalLength)
        .attr('stroke-dashoffset', totalLength)
        .transition()
        .duration(750)
        .ease(d3.easeLinear)
        .attr('stroke-dashoffset', 0);
    };

    const likesLine = d3.line<any>()
      .defined(d => !isNaN(d.medianLikes))
      .x(d => (xScale(d.date) || 0) + xScale.bandwidth() / 2)
      .y(d => yScale(d.medianLikes || 0))
      .curve(d3.curveMonotoneX);

    const retweetsLine = d3.line<any>()
      .defined(d => !isNaN(d.medianRetweets))
      .x(d => (xScale(d.date) || 0) + xScale.bandwidth() / 2)
      .y(d => yScale(d.medianRetweets || 0))
      .curve(d3.curveMonotoneX);

    const repliesLine = d3.line<any>()
      .defined(d => !isNaN(d.medianReplies))
      .x(d => (xScale(d.date) || 0) + xScale.bandwidth() / 2)
      .y(d => yScale(d.medianReplies || 0))
      .curve(d3.curveMonotoneX);

    const validDateRange = dateRange.filter(d =>
      !isNaN(d.medianLikes) &&
      !isNaN(d.medianRetweets) &&
      !isNaN(d.medianReplies)
    );

    g.append('path')
      .datum(validDateRange)
      .attr('fill', 'none')
      .attr('stroke', '#ff9500')
      .attr('stroke-width', 2)
      .attr('d', likesLine)
      .call(animateLine);

    g.append('path')
      .datum(validDateRange)
      .attr('fill', 'none')
      .attr('stroke', '#34D399')
      .attr('stroke-width', 2)
      .attr('d', retweetsLine)
      .call(animateLine);

    g.append('path')
      .datum(validDateRange)
      .attr('fill', 'none')
      .attr('stroke', '#60A5FA')
      .attr('stroke-width', 2)
      .attr('d', repliesLine)
      .call(animateLine);

    g.selectAll('circle')
      .data(dateRange)
      .enter()
      .append('circle')
      .attr('cx', d => (xScale(d.date) || 0) + xScale.bandwidth() / 2)
      .attr('cy', d => yScale(d.medianLikes || 0))
      .attr('r', 5)
      .attr('fill', 'transparent')
      .attr('stroke', 'transparent')
      .on('click', (_event, d) => onDaySelect?.(d.tweets.map(t => t.tweet_id)));

    const xAxis = d3.axisBottom(xScale)
      .tickFormat((d: string) => {
        const date = new Date(d);
        return d3.timeFormat('%d')(date);
      })
      .tickSize(0);

    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis)
      .call(g => g.select('.domain').style('stroke', '#444').style('stroke-width', 1))
      .selectAll('text')
      .style('text-anchor', 'middle')
      .attr('dy', '1.15em')
      .style('fill', '#fff')
      .style('font-size', '12px');

    const isValidDate = (d: Date): boolean => d instanceof Date && !isNaN(d.getTime());

    const xAxisWeekNotches = d3.axisBottom(xScale)
      .tickValues(
        isValidDate(startDate) && isValidDate(endDate)
          ? d3.timeWeek.every(1)!.range(startDate as Date, endDate as Date)
              .map(d => d.toISOString().split('T')[0])
          : []
      )
      .tickFormat(() => '')
      .tickSize(4);

    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(xAxisWeekNotches)
      .call(g => g.select('.domain').remove())
      .selectAll('.tick')
      .style('stroke', '#fff');

    g.append('g')
      .call(d3.axisLeft(yScale).ticks(5).tickFormat(d3.format('.0f')))
      .style('color', '#fff')
      .selectAll('text')
      .style('font-size', '14px');
  }, [tweets, monthWindow, onDaySelect, yMax]);

  return (
    <div className="w-full bg-black border border-gray-700 rounded-lg p-2 mt-4">
      <h2 className="text-white text-lg mb-2">Engagement Metrics</h2>
      <LegendControl type="metrics" />
      <svg ref={svgRef} className="w-full bg-black rounded-md"></svg>
    </div>
  );
};