const KNOWN_GROUP_COLORS: { [key: string]: string } = {
    'tel': '#4ECDC4',    
    'alp': '#DB261D',   
    'gre': '#009C3D',  
    'lib': '#000080', 
    'onp': '#F36D24',
    'lpt': '#60183C',
    'uap': '#FFFF00'
  };
  
  // Store dynamically generated colors for groups
  const dynamicGroupColors: { [key: string]: string } = {};
  
  // Generate a deterministic color based on groupName
  const generateGroupColor = (groupName: string): string => {
    // Use hash function to generate a number from groupName
    const hash = groupName.toLowerCase().split('').reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    
    // Use the hash to pick a hue value (0-360)
    const hue = Math.abs(hash % 360);
    
    // Create HSL color with fixed saturation and lightness
    return `hsl(${hue}, 70%, 60%)`;
  };
  
  // Get or generate a color for a group
  export const getGroupColor = (groupName: string): string => {
    // Normalize group name to lowercase to ensure consistency
    const normalizedGroupName = groupName.toLowerCase();
    console.warn(groupName);
    // Return pre-defined color if exists
    if (KNOWN_GROUP_COLORS[normalizedGroupName]) {
      return KNOWN_GROUP_COLORS[normalizedGroupName];
    }
    
    // Return previously generated color if exists
    if (dynamicGroupColors[normalizedGroupName]) {
      return dynamicGroupColors[normalizedGroupName];
    }
    
    // Generate new color
    const newColor = generateGroupColor(normalizedGroupName);
    dynamicGroupColors[normalizedGroupName] = newColor;
    return newColor;
  };
  
  // Export known colors for direct access
  export const KNOWN_GROUP_COLORS_CONST = KNOWN_GROUP_COLORS;