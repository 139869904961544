import { useState, useEffect, useCallback } from 'react';
import { TweetList } from './components/TweetList';
import { TweetVisualization } from './components/TweetVisualization';
import { TweetMinimapVisualization } from './components/TweetMiniMapVisualization';
import { EngagementVisualization } from './components/EngagementVisualization';
import { ArcDiagram } from './components/ArcDiagram';
import { LegendControl } from './components/LegendControl';
import { TwitterService } from './services/twitter.service';
import { TwitterConnect } from './components/TwitterConnect';


interface User {
  id: string;
  name: string;
  username: string;
}

interface GetKnownUsersResponse {
  users: User[];
  groups: { [key: string]: string[] };
}

function App() {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [userHandles, setUserHandles] = useState<{ [key: string]: string }>({});
  const [userToGroup, setUserToGroup] = useState<{ [key: string]: string }>({});
  const [activeUsers, setActiveUsers] = useState<string[]>([]);
  const [lastSelectedUser, setLastSelectedUser] = useState<string | null>(null);
  const [selectedTweetIds, setSelectedTweetIds] = useState<string[]>([]);
  const [allTweets, setAllTweets] = useState<{ [key: string]: any[] }>({});
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [monthWindow, setMonthWindow] = useState<string>(() => {
    return localStorage.getItem('minimapMonthWindow') || "2025-03-01";
  });

  const [usersInSelection, setUsersInSelection] = useState<string[]>([]);

  const selectedTweets = Object.fromEntries(
    Object.entries(allTweets).map(([userId, tweets]) => [
      userId,
      tweets.filter(tweet => selectedTweetIds.includes(tweet.tweet_id)),
    ])
  );

  const handleMonthWindowChange = useCallback((startDate: string) => {
    setMonthWindow(startDate);
  }, []);

  const handleActiveUsersChange = useCallback((users: string[]) => {
    setActiveUsers(users);
    if (!lastSelectedUser && users.length > 0) {
      setLastSelectedUser(users[users.length - 1]);
    }
  }, [lastSelectedUser]);

  const handleTweetsUpdate = useCallback((tweets: { [key: string]: any[] }) => {
    setAllTweets(tweets);
  }, []);

  const handleDaySelect = useCallback((tweetIds: string[]) => {
    console.log('App received tweetIds:', tweetIds);
    setSelectedTweetIds([...tweetIds]);

    const selectedTweet = Object.values(allTweets)
      .flat()
      .find(tweet => tweetIds.includes(tweet.tweet_id));
    if (selectedTweet) {
      setLastSelectedUser(selectedTweet.author_id);
    }
  }, [allTweets]);

  useEffect(() => {
    const fetchKnownUsers = async () => {
      try {
        const response = await TwitterService.getKnownUsers() as GetKnownUsersResponse;
        if (response && response.users && response.users.length > 0) {
          const userIds = response.users.map((user: User) => user.id);
          setSelectedUsers(userIds);
          const handles = response.users.reduce((acc: { [key: string]: string }, user: User) => {
            acc[user.id] = user.username;
            return acc;
          }, {});
          setUserHandles(handles);

          const groups = response.groups || {};
          const userToGroupMapping: { [key: string]: string } = {};
          Object.entries(groups).forEach(([groupName, userIds]: [string, string[]]) => {
            userIds.forEach(userId => {
              userToGroupMapping[userId] = groupName;
            });
          });
          setUserToGroup(userToGroupMapping);
          console.warn(userToGroupMapping);
        }
      } catch (error) {
        console.error('Error fetching known users:', error);
      }
    };

    fetchKnownUsers();
  }, []);

  useEffect(() => {
    const updateUserHandles = async () => {
      if (Object.keys(allTweets).length === 0) return;

      const mentionsSet = new Set<string>();
      Object.values(allTweets).flat().forEach(tweet => {
        const matches = tweet.text.match(/@(\w+)/g);
        const mentions: string[] = matches ? matches : [];
        mentions.forEach((mention: string) => {
          const handle = mention.slice(1);
          if (!Object.values(userHandles).includes(handle)) {
            mentionsSet.add(handle);
          }
        });
      });

      console.log('Mentions found in tweets:', Array.from(mentionsSet));

      const newHandles = Array.from(mentionsSet);
      if (newHandles.length > 0) {
        try {
          const newUsers: User[] = [];
          for (const handle of newHandles) {
            const userId = `user_${handle}`;
            newUsers.push({ id: userId, username: handle, name: handle });
          }

          const updatedHandles = { ...userHandles };
          let hasChanges = false;
          newUsers.forEach(user => {
            if (!Object.keys(updatedHandles).some(id => updatedHandles[id] === user.username)) {
              updatedHandles[user.id] = user.username;
              hasChanges = true;
            }
          });

          if (hasChanges) {
            setUserHandles(updatedHandles);
          }
        } catch (error) {
          console.error('Error fetching user data for mentions:', error);
        }
      }
    };

    updateUserHandles();
  }, [allTweets, userHandles]);

  // Compute users with tweets in the selected monthWindow
  useEffect(() => {
    const startDate = new Date(monthWindow);
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 1);

    const usersWithTweets = new Set<string>();
    Object.entries(allTweets).forEach(([userId, tweets]) => {
      const hasTweetsInRange = tweets.some(tweet => {
        const tweetDate = new Date(tweet.created_at);
        return tweetDate >= startDate && tweetDate < endDate;
      });
      if (hasTweetsInRange) {
        usersWithTweets.add(userId);
      }
    });

    setUsersInSelection(Array.from(usersWithTweets));
  }, [allTweets, monthWindow]);

  const usersForLegend = activeUsers.length > 0 ? activeUsers : selectedUsers;

  return (
    <div className="min-h-screen bg-gray-950">
      <nav className="bg-gray-900 border-b border-gray-700">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <span className="text-2xl font-bold text-orange-500">retent.io</span>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 relative">
        <div className="bg-gray-900 rounded-lg shadow-lg border border-gray-700 p-6 space-y-6">
          <div className="grid grid-cols-1 gap-4">
            <TweetMinimapVisualization 
                selectedUsers={selectedUsers}
                onMonthWindowChange={handleMonthWindowChange}
              />
          </div>
          <div className="grid grid-cols-1 gap-4">
            <LegendControl 
              type="users"
              users={usersInSelection.map(id => ({
                id,
                handle: userHandles[id] || id,
                selected: id === lastSelectedUser,
                group: userToGroup[id] || 'unknown',
              }))}
              userHandles={userHandles}
              userToGroup={userToGroup}
              userListType="selected"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
            <TweetVisualization 
              selectedUserId={lastSelectedUser}
              selectedUsers={selectedUsers}
              userHandles={userHandles}
              onActiveUsersChange={handleActiveUsersChange}
              onDaySelect={handleDaySelect}
              onTweetsUpdate={handleTweetsUpdate}
              monthWindow={monthWindow}
              yMax={15}
              userToGroup={userToGroup}
            />
            <EngagementVisualization 
              selectedUsers={selectedUsers}
              userHandles={userHandles}
              onTweetsUpdate={handleTweetsUpdate}
              onDaySelect={handleDaySelect}
              monthWindow={monthWindow}
              yMax={15}
              userToGroup={userToGroup}
            />
            <ArcDiagram
              displayType='selected'
              tweets={allTweets}
              userHandles={userHandles}
              partyUserIds={selectedUsers}
              selectedTweets={selectedTweetIds.length > 0 ? selectedTweets : undefined}
            />
          </div>
          <TweetList 
            selectedUsers={activeUsers.length > 0 ? activeUsers : selectedUsers}
            selectedTweetIds={selectedTweetIds}
            tweets={allTweets}
          />
        </div>

        <button
          onClick={() => setIsSearchOpen(true)}
          className="fixed bottom-4 right-4 w-12 h-12 bg-orange-500 text-white rounded-full flex items-center justify-center hover:bg-orange-600 transition-colors"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </button>

        {isSearchOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gray-800 rounded-lg p-6 w-full max-w-md">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-white text-lg">Search Users/Groups</h2>
                <button
                  onClick={() => setIsSearchOpen(false)}
                  className="text-gray-400 hover:text-gray-200"
                >
                  ✕
                </button>
              </div>
              <TwitterConnect
                selectedUsers={usersForLegend}
                onSelectedUsersChange={(users) => {
                  setSelectedUsers(users);
                  setIsSearchOpen(false);
                }}
                onUserHandlesChange={setUserHandles}
              />
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default App;