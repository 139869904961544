import { AnimatedBarChart } from './AnimatedBarChart';
import { createPortal } from 'react-dom';

export interface TweetWithMetrics {
  public_metrics?: {
    retweet_count: number;
    reply_count: number;
    like_count: number;
    quote_count: number;
    bookmark_count: number;
    impression_count: number;
  };
}

export interface TooltipProps {
  x: number;
  y: number;
  groupName: string;
  count: number;
  sentimentAvg: number;
  userList: string;
  tweetIds: string;
  sentimentScores: number[];
  tweets: TweetWithMetrics[];
}

export const Tooltip: React.FC<TooltipProps> = ({
  x,
  y,
  groupName,
  count,
  sentimentAvg,
  tweets,
}) => {
  // Calculate total engagement metrics
  const totalMetrics = tweets.reduce((acc, tweet) => ({
    retweets: acc.retweets + (tweet.public_metrics?.retweet_count || 0),
    likes: acc.likes + (tweet.public_metrics?.like_count || 0),
    replies: acc.replies + (tweet.public_metrics?.reply_count || 0),
  }), { retweets: 0, likes: 0, replies: 0 });

  // Normalize the values to make them more comparable
  const maxEngagement = Math.max(
    totalMetrics.retweets,
    totalMetrics.likes,
    totalMetrics.replies
  );

  const chartData = [
    { 
      label: 'Retweets', 
      value: totalMetrics.retweets / maxEngagement * 100, 
      color: '#4ECDC4',
      sentiment: sentimentAvg 
    },
    { 
      label: 'Likes', 
      value: totalMetrics.likes / maxEngagement * 100, 
      color: '#DB261D',
      sentiment: sentimentAvg 
    },
    { 
      label: 'Replies', 
      value: totalMetrics.replies / maxEngagement * 100, 
      color: '#009C3D',
      sentiment: sentimentAvg 
    },
    { 
      label: 'Sentiment', 
      value: Math.abs(sentimentAvg * 1000),
      color: sentimentAvg >= 0 ? '#34D399' : '#EF4444',
      sentiment: sentimentAvg 
    },
  ];

  const tooltip = (
    <div
      id="tooltip"
      style={{
        position: 'fixed',
        left: x + 10,
        top: y - 10,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: '10px',
        borderRadius: '4px',
        color: 'white',
        zIndex: 1000,
        minWidth: '250px',
        pointerEvents: 'none',
      }}
    >
      <div style={{ marginBottom: '10px' }}>
        <div style={{ fontWeight: 'bold' }}>{groupName}</div>
        <div>{count} tweets</div>
      </div>
      <AnimatedBarChart data={chartData} width={200} height={100} />
    </div>
  );

  return createPortal(tooltip, document.body);
}; 