import { motion, AnimatePresence } from 'framer-motion';
import { getGroupColor } from '../utils/groupColors';

interface LegendControlProps {
  type: 'users' | 'metrics';
  users?: { id: string; handle: string; selected?: boolean }[];
  userHandles?: { [key: string]: string };
  userToGroup?: { [key: string]: string };
  userListType?: 'all' | 'selected';
}

export const LegendControl: React.FC<LegendControlProps> = ({ type, users, userHandles, userToGroup, userListType = 'all' }) => {
  if (type === 'users' && users && userHandles) {
    const displayUsers = userListType === 'selected'
      ? users.filter(user => userHandles && Object.keys(userHandles).includes(user.id))
      : users;

    return (
      <div className="flex justify-center gap-4 p-2 bg-black border border-gray-700 rounded-lg">
        <AnimatePresence initial={true}>
          {displayUsers.map((user, index) => (
            <motion.div
              key={user.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="flex items-center gap-2"
            >
              <div
                className="w-8 h-8 rounded-sm flex items-center justify-center"
                style={{
                  backgroundColor: getGroupColor(userToGroup?.[user.id] || 'unknown'),
                  opacity: user.selected ? 0.75 : 1.0,
                }}
              >
                <div
                  className="w-6 h-6 rounded-sm flex-shrink-0 bg-gray-700"
                  style={{ opacity: user.selected ? 0.75 : 1.0 }}
                >
                  <img
                    src={`/profile_images/${user.id}_bigger.jpg`}
                    alt={`${user.handle}'s profile`}
                    className="w-full h-full object-cover rounded-sm"
                    onError={(e) => (e.currentTarget.src = '/profile_images/1793746859284148225_bigger.jpg')}
                  />
                </div>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    );
  }

  if (type === 'metrics') {
    const metrics = [
      { name: 'Likes', color: '#ff9500' },
      { name: 'Retweets', color: '#34D399' },
      { name: 'Replies', color: '#60A5FA' },
    ];
    return (
      <div className="flex justify-center gap-4 p-2 bg-black border border-gray-700 rounded-lg">
        {metrics.map((metric, _) => (
          <div key={metric.name} className="flex items-center gap-2">
            <div
              className="w-4 h-4 rounded-sm flex-shrink-0"
              style={{ backgroundColor: metric.color }}
            />
            <span className="text-white text-sm">{metric.name}</span>
          </div>
        ))}
      </div>
    );
  }

  return null;
};