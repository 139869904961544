import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { TwitterService } from '../services/twitter.service';
import { getGroupColor } from '../utils/groupColors';
import { Tooltip, TooltipProps } from './Tooltip';

interface TweetWithSentiment {
  tweet_id: string;
  text: string;
  sentiment_score: number;
  sentiment_label: string;
  created_at: string;
  tweet_type: 'original' | 'retweet' | 'reply' | 'quote';
  author_id: string;
  public_metrics?: {
    retweet_count: number;
    reply_count: number;
    like_count: number;
    quote_count: number;
    bookmark_count: number;
    impression_count: number;
  };
}

interface TweetVisualizationProps {
  selectedUserId: string | null;
  selectedUsers: string[];
  onActiveUsersChange?: (users: string[]) => void;
  userHandles: { [key: string]: string };
  onDaySelect?: (tweetIds: string[]) => void;
  onTweetsUpdate?: (tweets: { [key: string]: any[] }) => void;
  monthWindow: string;
  yMax?: number;
  userToGroup: { [key: string]: string };
}

export const TweetVisualization: React.FC<TweetVisualizationProps> = ({
  selectedUserId,
  selectedUsers,
  onActiveUsersChange,
  userHandles,
  onDaySelect,
  onTweetsUpdate,
  monthWindow,
  yMax = 15,
  userToGroup,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [tweets, setAllTweets] = useState<{ [key: string]: TweetWithSentiment[] }>({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [tooltipData, setTooltipData] = useState<TooltipProps | null>(null);
  console.log(selectedUserId);
  useEffect(() => {
    const fetchTweetsForUsers = async () => {
      const newTweets: { [key: string]: TweetWithSentiment[] } = {};
      try {
        for (const userId of selectedUsers) {
          const response = await TwitterService.getUserReport(userId);
          if (response && response.length > 0) {
            newTweets[userId] = response.map((tweet: any) => ({
              tweet_id: tweet.tweet_id,
              text: tweet.text,
              sentiment_score: tweet.sentiment_score || 0,
              sentiment_label: tweet.sentiment_label || 'neutral',
              created_at: tweet.created_at,
              tweet_type: tweet.tweet_type || 'original',
              author_id: userId,
              public_metrics: {
                retweet_count: 0,
                reply_count: 0,
                like_count: 0,
                quote_count: 0,
                bookmark_count: 0,
                impression_count: 0,
              },
            }));
          }
        }

        const engagementResponse = await TwitterService.getUserTweetsMultiple(selectedUsers);
        const engagementData = engagementResponse?.data || [];
        engagementData.forEach((tweet: any) => {
          const userId = tweet.author_id;
          if (!newTweets[userId]) newTweets[userId] = [];
          const existingTweet = newTweets[userId].find(t => t.tweet_id === tweet.id);
          const metrics = tweet.public_metrics || {
            retweet_count: 0,
            reply_count: 0,
            like_count: 0,
            quote_count: 0,
            bookmark_count: 0,
            impression_count: 0,
          };
          if (existingTweet) {
            existingTweet.public_metrics = metrics;
          } else {
            newTweets[userId].push({
              tweet_id: tweet.id,
              text: tweet.text,
              created_at: tweet.created_at,
              author_id: userId,
              sentiment_score: 0,
              sentiment_label: 'neutral',
              tweet_type: 'original',
              public_metrics: metrics,
            });
          }
        });

        setAllTweets(newTweets);
        setIsDataLoaded(true);
        onTweetsUpdate?.(newTweets);
        onActiveUsersChange?.(Object.keys(newTweets));
      } catch (error) {
        console.error('Error fetching tweets:', error);
        setIsDataLoaded(true);
      }
    };

    if (selectedUsers.length > 0) {
      setIsDataLoaded(false);
      fetchTweetsForUsers();
    } else {
      setAllTweets({});
      setIsDataLoaded(true);
      onActiveUsersChange?.([]);
    }
  }, [selectedUsers]);

  const renderVisualization = () => {
    if (!svgRef.current || !isDataLoaded) return;

    const allTweets = Object.values(tweets).flat();
    if (allTweets.length === 0) return;

    const startDate = new Date(monthWindow);
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 1);

    const tweetsByDate = allTweets
      .filter(tweet => {
        const tweetDate = new Date(tweet.created_at);
        return tweetDate >= startDate && tweetDate < endDate;
      })
      .reduce((acc, tweet) => {
        const date = new Date(tweet.created_at).toISOString().split('T')[0];
        if (!acc[date]) acc[date] = { count: 0, sentimentSum: 0, tweets: [] };
        acc[date].count++;
        acc[date].sentimentSum += tweet.sentiment_score || 0;
        acc[date].tweets.push(tweet);
        return acc;
      }, {} as Record<string, { count: number; sentimentSum: number; tweets: TweetWithSentiment[] }>);

    const dailyData = d3.timeDay.range(startDate, endDate).map(date => {
      const dateStr = date.toISOString().split('T')[0];
      const dayData = tweetsByDate[dateStr] || { count: 0, sentimentSum: 0, tweets: [] };
      return {
        key: dateStr,
        date: dateStr,
        count: dayData.count,
        hasData: dayData.count > 0,
        tweets: dayData.tweets,
        sentimentAvg: dayData.count ? dayData.sentimentSum / dayData.count : 0,
      };
    });

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const margin = { top: 10, right: 20, bottom: 30, left: 60 };
    const width = svgRef.current.clientWidth - margin.left - margin.right;
    const height = yMax * 20;

    svg.attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom);

    if (!d3.select('body').select('#tooltip').size()) {
      d3.select('body')
        .append('div')
        .attr('id', 'tooltip')
        .style('position', 'absolute')
        .style('visibility', 'hidden')
        .style('background-color', 'rgba(0, 0, 0, 0.8)')
        .style('color', 'white')
        .style('padding', '8px')
        .style('border-radius', '4px')
        .style('font-size', '12px')
        .style('z-index', '10000')
        .style('pointer-events', 'none');
    }

    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const xScale = d3.scaleBand()
      .domain(dailyData.map(d => d.date))
      .range([0, width])
      .padding(0.1);

    const yScale = d3.scaleLinear()
      .domain([0, yMax])
      .range([height, 0]);

    const yAxis = d3.axisLeft(yScale).ticks(Math.min(yMax / 5, 6)).tickFormat(d3.format('.0f'));
    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(xScale).tickFormat(() => '').tickSize(4))
      .call(g => g.select('.domain').style('stroke', '#444'));

    const xAxis = d3.axisBottom(xScale)
      .tickFormat((d: string) => d3.timeFormat('%d')(new Date(d)))
      .tickSize(0);

    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis)
      .call(g => g.select('.domain').remove())
      .selectAll('text')
      .style('fill', '#fff');

    dailyData.forEach((day) => {
      if (day.hasData) {
        const x = xScale(day.date)!;
        const cappedCount = Math.min(day.count, yMax);
        const totalHeight = height - yScale(cappedCount);

        const tweetsByGroup = day.tweets.reduce((acc, tweet) => {
          const groupName = userToGroup[tweet.author_id] || 'unknown';
          if (!acc[groupName]) acc[groupName] = [];
          acc[groupName].push(tweet);
          return acc;
        }, {} as { [groupName: string]: TweetWithSentiment[] });

        const totalTweets = day.count;

        const groupData = Object.entries(tweetsByGroup).map(([groupName, groupTweets]) => ({
          groupName,
          tweets: groupTweets,
          count: groupTweets.length,
          proportion: groupTweets.length / totalTweets,
          sentimentAvg: groupTweets.length ? groupTweets.reduce((sum, tweet) => sum + tweet.sentiment_score, 0) / groupTweets.length : 0,
        }));

        groupData.sort((a, b) => b.count - a.count);

        let cumulativeHeight = 0;
        groupData.forEach(({ groupName, proportion, tweets, count, sentimentAvg }) => {
          const groupHeight = totalHeight * proportion;
          const finalY = height - cumulativeHeight - groupHeight;

          g.append('rect')
            .attr('x', x)
            .attr('y', finalY)
            .attr('width', xScale.bandwidth())
            .attr('height', groupHeight)
            .attr('fill', getGroupColor(groupName))
            .style('pointer-events', 'none');

          g.append('rect')
            .attr('class', 'hover-mask')
            .attr('x', x)
            .attr('y', finalY)
            .attr('width', xScale.bandwidth())
            .attr('height', groupHeight)
            .attr('fill', 'transparent')
            .style('pointer-events', 'all')
            .on('mouseenter', function(event) {
              d3.select(this).transition().duration(50).attr('fill', 'rgba(255,255,255,0.1)');
              const uniqueUsers = Array.from(new Set(tweets.map(t => t.author_id)));
              const userList = uniqueUsers.map(userId => `@${userHandles[userId] || userId}`).join(', ');
              const tweetIds = tweets.map(t => t.tweet_id).join(', ');
              const sentimentScores = tweets.map(tweet => tweet.sentiment_score);

              // Get the mouse position relative to the viewport
              // const rect = event.target.getBoundingClientRect();
              const x = event.clientX;
              const y = event.clientY;

              setTooltipData({
                x,
                y,
                groupName,
                count,
                sentimentAvg,
                userList,
                tweetIds,
                sentimentScores,
                tweets,
              });
            })
            .on('mouseleave', function() {
              d3.select(this).transition().duration(50).attr('fill', 'rgba(255,255,255,0.05)');
              setTooltipData(null);
            })
            .on('mousemove', function(event) {
              d3.select('#tooltip')
                .style('left', (event.pageX + 10) + 'px')
                .style('top', (event.pageY - 10) + 'px');
            })
            .on('click', function() {
              onDaySelect?.(day.tweets.map(t => t.tweet_id));
              d3.select(this)
                .attr('fill', 'rgba(255, 255, 0, 0.3)')
                .transition()
                .duration(500)
                .attr('fill', 'transparent');
            });

          cumulativeHeight += groupHeight;
        });
      }
    });

    g.append('g').call(yAxis).style('color', '#fff');
  };

  useEffect(() => {
    renderVisualization();
  }, [tweets, isDataLoaded, monthWindow, yMax, userToGroup]);

  return (
    <div className="relative w-full bg-black border border-gray-700 rounded-lg p-4">
      <svg ref={svgRef} className="w-full" />
      {tooltipData && <Tooltip {...tooltipData} />}
    </div>
  );
};