import { useEffect, useRef } from 'react';
import * as d3 from 'd3';

interface BarData {
  label: string;
  value: number;
  color: string;
  sentiment?: number;
}

interface AnimatedBarChartProps {
  data: BarData[];
  width?: number;
  height?: number;
  padding?: number;
}

export const AnimatedBarChart: React.FC<AnimatedBarChartProps> = ({
  data,
  width = 200,
  height = 100,
  padding = 20,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const maxValue = d3.max(data, d => d.value) || 0;
    const barHeight = (height - padding * 2) / data.length;
    const labelWidth = 80; // Fixed width for labels
    const barWidth = width - padding * 2 - labelWidth - 10; // Remaining width for bars
    const xScale = d3.scaleLinear()
      .domain([0, maxValue])
      .range([0, barWidth]);

    const g = svg.append('g')
      .attr('transform', `translate(${padding},${padding})`);

    // Add labels
    g.selectAll('text.label')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'label')
      .attr('x', 0)
      .attr('y', (_, i) => i * barHeight + barHeight / 2)
      .attr('dy', '0.35em')
      .style('font-size', '12px')
      .style('fill', '#fff')
      .text(d => d.label);

    // Add bars
    g.selectAll('rect')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', labelWidth + 5) // Start after labels
      .attr('y', (_, i) => i * barHeight)
      .attr('width', 0)
      .attr('height', barHeight - 2)
      .attr('fill', d => d.color)
      .transition()
      .duration(750)
      .attr('width', d => xScale(d.value));

    // Add values and emoticons
    g.selectAll('.value')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'value')
      .attr('x', d => labelWidth + 5 + xScale(d.value) + 5)
      .attr('y', (_, i) => i * barHeight + barHeight / 2)
      .attr('dy', '0.35em')
      .style('font-size', '12px')
      .style('fill', '#fff')
      .text(d => {
        const value = d.value.toFixed(0);
        if (d.label === 'Sentiment' && d.sentiment !== undefined) {
          const emoticon = d.sentiment > 0 ? '😊' : d.sentiment < 0 ? '😞' : '😐';
          return `${value} ${emoticon}`;
        }
        return value;
      });
  }, [data, width, height, padding]);

  return (
    <svg
      ref={svgRef}
      width={width}
      height={height}
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
    />
  );
}; 