import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { TwitterService } from '../services/twitter.service';

interface TweetWithSentiment {
  tweet_id: string;
  created_at: string;
  author_id: string;
}

interface TweetMinimapVisualizationProps {
  selectedUsers: string[];
  onMonthWindowChange: (startDate: string) => void;
}

export const TweetMinimapVisualization: React.FC<TweetMinimapVisualizationProps> = ({ selectedUsers, onMonthWindowChange }) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [tweets, setTweets] = useState<{ [key: string]: TweetWithSentiment[] }>({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const fetchTweetsForUsers = async () => {
      const newTweets: { [key: string]: TweetWithSentiment[] } = {};
      console.log('Minimap: Fetching tweets for users:', selectedUsers);
      try {
        for (const userId of selectedUsers) {
          const response = await TwitterService.getUserReport(userId);
          if (response && response.length > 0) {
            newTweets[userId] = response.map((tweet: any) => ({
              tweet_id: tweet.tweet_id,
              created_at: tweet.created_at,
              author_id: userId,
            }));
          }
        }

        const engagementResponse = await TwitterService.getUserTweetsMultiple(selectedUsers);
        const engagementData = engagementResponse?.data || [];
        engagementData.forEach((tweet: any) => {
          const userId = tweet.author_id;
          if (!newTweets[userId]) newTweets[userId] = [];
          if (!newTweets[userId].find(t => t.tweet_id === tweet.id)) {
            newTweets[userId].push({
              tweet_id: tweet.id,
              created_at: tweet.created_at,
              author_id: userId,
            });
          }
        });

        const dates = Object.values(newTweets).flat().map(t => new Date(t.created_at));
        const maxDate = dates.length ? new Date(Math.max(...dates.map(d => d.getTime()))) : new Date();
        const minDate = dates.length ? new Date(Math.min(...dates.map(d => d.getTime()))) : new Date(maxDate);
        const storedMonth = localStorage.getItem('minimapMonthWindow');
        const storedDate = storedMonth ? new Date(storedMonth) : null;

        if (!storedDate || storedDate < minDate || storedDate > maxDate) {
          const defaultMonth = maxDate.toISOString().slice(0, 10);
          localStorage.setItem('minimapMonthWindow', defaultMonth);
          onMonthWindowChange(defaultMonth);
        }

        setTweets(newTweets);
        setIsDataLoaded(true);
      } catch (error) {
        console.error('Minimap: Error fetching tweets:', error);
        setIsDataLoaded(true);
      }
    };

    if (selectedUsers.length > 0) {
      setIsDataLoaded(false);
      fetchTweetsForUsers();
    } else {
      console.log('Minimap: No selected users');
      localStorage.removeItem('minimapMonthWindow');
      setTweets({});
      setIsDataLoaded(true);
    }
  }, [selectedUsers, onMonthWindowChange]);

  useEffect(() => {
    if (!svgRef.current || !isDataLoaded) return;

    const allTweets = Object.values(tweets).flat();
    console.log('Minimap: Tweets to render:', allTweets);

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const width = 600;
    const height = 100;
    const margin = { top: 5, right: 5, bottom: 5, left: 5 };

    svg.attr('width', width).attr('height', height);

    const maxDate = allTweets.length ? new Date(Math.max(...allTweets.map(t => new Date(t.created_at).getTime()))) : new Date();
    const startDate = allTweets.length ? new Date(Math.min(...allTweets.map(t => new Date(t.created_at).getTime()))) : new Date(maxDate);

    // Zoomed-in range (2x zoom from previous update)
    const originalStartDate = new Date(startDate);
    originalStartDate.setMonth(originalStartDate.getMonth() - 6);
    const fullRangeDays = d3.timeDay.count(originalStartDate, maxDate);
    const zoomedRangeDays = fullRangeDays / 2;

    const zoomedStartDate = new Date(maxDate);
    zoomedStartDate.setDate(zoomedStartDate.getDate() - zoomedRangeDays);

    const endDate = new Date(maxDate);
    endDate.setDate(endDate.getDate() + 1);

    const tweetsByDate = allTweets
      .filter(tweet => {
        const tweetDate = new Date(tweet.created_at);
        return tweetDate >= zoomedStartDate && tweetDate <= endDate;
      })
      .reduce((acc, tweet) => {
        const date = new Date(tweet.created_at).toISOString().split('T')[0];
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {} as Record<string, number>);

    const dailyData = d3.timeDay.range(zoomedStartDate, endDate).map(date => {
      const dateStr = date.toISOString().split('T')[0];
      return { date: dateStr, count: tweetsByDate[dateStr] || 0 };
    });
    console.log('Minimap: Daily data:', dailyData);

    const xScale = d3.scaleBand()
      .domain(dailyData.map(d => d.date))
      .range([margin.left, width - margin.right])
      .padding(0.2);

    // Cap the yScale at 20
    const yScale = d3.scaleLinear()
      .domain([0, 20]) // Cap at 20 for "normal" values
      .range([height - margin.bottom, margin.top])
      .clamp(true); // Ensure values above 20 are capped

    const g = svg.append('g');

    // Render bars with capped heights
    const bars = g.selectAll('rect.bar')
      .data(dailyData)
      .join('rect')
      .attr('class', 'bar')
      .attr('x', d => xScale(d.date) || 0)
      .attr('y', d => yScale(Math.min(d.count, 20))) // Cap the count at 20 for scaling
      .attr('width', xScale.bandwidth())
      .attr('height', d => height - margin.bottom - yScale(Math.min(d.count, 20)))
      .attr('fill', d => d.count > 20 ? '#ff5555' : '#888888') // Red fill for capped bars
      .attr('stroke', '#444444')
      .attr('stroke-width', 0.5);

    // Selection window (50px wide)
    const rectWidth = 50;
    const initialX = localStorage.getItem('minimapMonthWindow')
      ? Math.min(xScale(localStorage.getItem('minimapMonthWindow')!) || margin.left, width - rectWidth - margin.right)
      : margin.left;

    const rect = g.append('rect')
      .attr('x', initialX)
      .attr('y', margin.top)
      .attr('width', rectWidth)
      .attr('height', height - margin.top - margin.bottom)
      .attr('fill', 'rgba(0, 255, 255, 0.2)')
      .attr('stroke', '#ffffff')
      .attr('stroke-width', 1)
      .attr('cursor', 'grab');

    // Fish-eye effect function (adjusted for capped yScale)
    const applyFishEyeEffect = (rectX: number) => {
      const rectCenter = rectX + rectWidth / 2;
      bars.each(function(d: any) {
        const bar = d3.select(this);
        const barX = xScale(d.date) || 0;
        const barWidth = xScale.bandwidth();
        const barCenter = barX + barWidth / 2;

        if (barX + barWidth >= rectX && barX <= rectX + rectWidth) {
          const distance = Math.abs(barCenter - rectCenter);
          const maxDistance = rectWidth / 2;
          const scaleFactor = 1 + Math.cos((distance / maxDistance) * (Math.PI / 2));

          bar.attr('width', barWidth * scaleFactor)
             .attr('height', (height - margin.bottom - yScale(Math.min(d.count, 20))) * scaleFactor)
             .attr('x', barX - (barWidth * (scaleFactor - 1)) / 2)
             .attr('y', yScale(Math.min(d.count, 20)) - ((height - margin.bottom - yScale(Math.min(d.count, 20))) * (scaleFactor - 1)) / 2)
             .attr('fill', d.count > 20 ? '#ff5555' : '#bbbbbb');
        } else {
          bar.attr('width', barWidth)
             .attr('height', height - margin.bottom - yScale(Math.min(d.count, 20)))
             .attr('x', barX)
             .attr('y', yScale(Math.min(d.count, 20)))
             .attr('fill', d.count > 20 ? '#ff5555' : '#888888');
        }
      });
    };

    // Initial fish-eye effect
    applyFishEyeEffect(initialX);

    // Drag behavior
    const drag = d3.drag<SVGRectElement, unknown>()
      .on('start', () => {
        rect.attr('cursor', 'grabbing');
      })
      .on('drag', (event) => {
        const newX = Math.max(margin.left, Math.min(width - rectWidth - margin.right, event.x));
        rect.attr('x', newX);
        applyFishEyeEffect(newX);
      })
      .on('end', (event) => {
        const newX = Math.max(margin.left, Math.min(width - rectWidth - margin.right, event.x));
        const dayIndex = Math.floor((newX - margin.left) / xScale.step());
        const selectedDate = dailyData[dayIndex]?.date || dailyData[dailyData.length - 1].date;
        if (selectedDate) {
          onMonthWindowChange(selectedDate);
          localStorage.setItem('minimapMonthWindow', selectedDate);
        }
        rect.attr('cursor', 'grab');
      });

    rect.call(drag);

    const storedDate = localStorage.getItem('minimapMonthWindow');
    if (storedDate && dailyData.some(d => d.date === storedDate)) {
      onMonthWindowChange(storedDate);
    } else if (dailyData.length > 0) {
      onMonthWindowChange(dailyData[dailyData.length - 1].date);
    }
  }, [tweets, isDataLoaded, onMonthWindowChange]);

  return (
    <div className="h-[100px] bg-black border border-gray-700 rounded-sm">
      <svg ref={svgRef} className="w-full h-full" />
    </div>
  );
};